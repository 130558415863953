import pagheViews from '@paghe/views'
import entities from '@paghe/entities'

const mapGestione = new Map<string, Gestione>([
    ['000004', 'crr'],
    ['000181', 'ftr'],
    ['000187', 'pss'],
])

const codPermAnagrafica = 'ANAG_DITTA'
const codPermAlterazioni = 'ALTERA_DITTA'

const tabPropsDefault: TabProps = {
    GROUP: 'anagraficaDitta',
    ICON: 'fa-regular fa-buildings',
    TITLE: 'Anagrafici',
    CODVOCEMENU: [...mapGestione.keys()],
}

//https://router.vuejs.org/guide/essentials/passing-props
function propsFn(route) {
    return {
        ...route.params,
        year: Number(route.params.year),
        month: Number(route.params.month),
        id: Number(route.params.id),
        idPadre: Number(route.params.idPadre),
        //mod: route.params.mod,
        //gestione: route.params.gestione,
    }
}

export const SETTINGS = Object.freeze({
    DITTE_ELENCO: {
        TITLE: 'Elenco ditte - Ditta - Anagrafici - Gestione - Paghe',
        LABEL: 'Elenco ditte',
        PATH: 'ditta/:gestione',
        NAME: 'DittaLista',
        TAB: { ...tabPropsDefault },
    },
    DITTA_DETTAGLIO: {
        TITLE: 'Dettaglio ditta - Ditta - Anagrafici - Gestione - Paghe',
        LABEL: 'Dettaglio ditta',
        PATH: 'ditta/:gestione/dettaglio/:year/:month/:id/:idPadre/:mod',
        NAME: 'DittaDettaglio',
    },
    //Children
    DITTA_ANAGRAFICA: {
        TITLE: null,
        LABEL: 'Dati anagrafici',
        PATH: 'anagrafica',
        NAME: 'DittaAnagrafica',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_DATI_INPS: {
        TITLE: null,
        LABEL: 'Dati Inps/altri enti',
        PATH: 'inps',
        NAME: 'DittaDatiInps',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_CENTRI_COSTO: {
        TITLE: null,
        LABEL: 'Centri di Costo',
        PATH: 'ccosto',
        NAME: 'DittaCentriCosto',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_SOST_IMPOSTA: {
        TITLE: null,
        LABEL: 'Dati sost.imposta',
        PATH: 'sost-imposta',
        NAME: 'DittaSostImposta',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_DATI_GESTIONE: {
        TITLE: null,
        LABEL: 'Dati di Gestione',
        PATH: 'gestione',
        NAME: 'DittaDatiGestione',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_OPZIONI: {
        TITLE: null,
        LABEL: 'Opzioni',
        PATH: 'opzioni',
        NAME: 'DittaOpzioni',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_ORGANICO: {
        TITLE: '',
        LABEL: 'Organico',
        PATH: 'organico',
        NAME: 'DittaOrganico',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_OPZIONISTAMPA: {
        TITLE: '',
        LABEL: 'Opzioni stampa',
        PATH: 'opzionistampa',
        NAME: 'OpzioniStampa',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_POSIZIONIINAIL: {
        TITLE: '',
        LABEL: 'Posizioni Inail',
        PATH: 'posizioniinail',
        NAME: 'PosizioniInail',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_STORICO: {
        TITLE: '',
        LABEL: 'Storico',
        PATH: 'storico',
        NAME: 'Storico',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_RAPPRESENTANTELEGALE: {
        TITLE: '',
        LABEL: 'Rappresentante Legale',
        PATH: 'RappresentanteLegale',
        NAME: 'RappresentanteLegale',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_BANCHE: {
        TITLE: '',
        LABEL: 'Banche',
        PATH: 'banche',
        NAME: 'Banche',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_GRUPPOALTERAZIONE: {
        TITLE: '',
        LABEL: 'Gruppi alterazione',
        PATH: 'GruppoAlterazione',
        NAME: 'GruppoAlterazione',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_APPALTO: {
        TITLE: '',
        LABEL: 'Appalti/commesse',
        PATH: 'appalto',
        NAME: 'Appalto',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    //Cantieri cassa edile
    DITTA_CASSA_EDILE: {
        TITLE: '',
        LABEL: 'Cantieri C.E.',
        PATH: 'cassaEdile',
        NAME: 'CassaEdile',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_FONDIDMAG: {
        TITLE: '',
        LABEL: 'Fondi/Dmag',
        PATH: 'fondidmag',
        NAME: 'FondiDmag',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_SCADENZIARIO: {
        TITLE: '',
        LABEL: 'Scadenziario',
        PATH: 'scadenziario',
        NAME: 'Scadenziario',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_ALTRI_ONERI: {
        TITLE: '',
        LABEL: 'Conguaglio altri oneri',
        PATH: 'altriOneri',
        NAME: 'AltriOneri',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_FORMULE: {
        TITLE: '',
        LABEL: 'Formule',
        PATH: 'formule',
        NAME: 'Formule',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_IMP_CEDOLINO: {
        TITLE: '',
        LABEL: 'Impostazione cedolino',
        PATH: 'impCedolino',
        NAME: 'impCedolino',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_UNITAPRODUTTIVEINPS: {
        TITLE: '',
        LABEL: 'Unità produttive INPS',
        PATH: 'unitaProduttiveINPS',
        NAME: 'UnitaProduttiveINPS',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_CAUSALIASSENTEISMO: {
        TITLE: '',
        LABEL: 'Causali assenteismo',
        PATH: 'causaliAssenteismo',
        NAME: 'CausaliAssenteismo',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_DIARIO: {
        TITLE: '',
        LABEL: 'Diario',
        PATH: 'diario',
        NAME: 'DiarioDitta',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_CAMPIPERSONALIZZATI: {
        TITLE: '',
        LABEL: 'Campi personalizzati',
        PATH: 'campiPersonalizzati',
        NAME: 'DittaCampiPersonalizzati',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_CONTRATTOSTRAORDINARIO: {
        TITLE: '',
        LABEL: 'Straordinario / Maggiorazioni',
        PATH: 'contrattoStraordinario',
        NAME: 'DittaContrattoStraordinario',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_ALTERAZIONE_DIVISORI_ORARIO: {
        TITLE: '',
        LABEL: 'Divisori/Orario',
        PATH: 'alterazioneDivisoriOrari',
        NAME: 'DittaAlterazioneDivisoriOrari',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_ALTERAZIONE_FESTIVITA: {
        TITLE: '',
        LABEL: 'Festività',
        PATH: 'alterazioniFestivita',
        NAME: 'DittaAlterazioniFestivita',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_ALTERAZIONE_FERIE: {
        TITLE: '',
        LABEL: 'Ferie/Ex fest./Rol',
        PATH: 'alterazioniFerie',
        NAME: 'DittaAlterazioniFerie',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_ISTITUTI_CONTRATTUALI: {
        TITLE: null,
        LABEL: 'Istituti Contrattuali',
        PATH: 'DittaIstitutiContrattuali/:codContratto?',
        NAME: 'DittaIstitutiContrattuali',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_ALTERAZIONE_VALORIZZAZIONE_ELEMENTI: {
        TITLE: '',
        LABEL: 'Valorizzazione elementi',
        PATH: 'AlterazioneElementi',
        NAME: 'DittaAlterazioniElementi',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_ALTERAZIONE_MESILITA_AGGIUNTIVE: {
        TITLE: '',
        LABEL: 'Mensilità aggiuntive',
        PATH: 'mensilitaAggiuntive/:codMensilita?',
        NAME: 'DittaAlterazioneMensilitaAggiuntive',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_ALTERAZIONE_INFORTUNIO: {
        TITLE: '',
        LABEL: 'Infortunio',
        PATH: 'alterazioniInfortunio',
        NAME: 'DittaAlterazioniInfortunio',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_PROGRESSIONE_RETRIBUTIVA: {
        TITLE: '',
        LABEL: 'Progressione retributiva',
        PATH: 'AlterazioneProgRetributiva',
        NAME: 'DittaAlterazioniProgRetributiva',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_ALTERAZIONE_MALATTIA: {
        TITLE: '',
        LABEL: 'Malattia',
        PATH: 'AlterazioneMalattia',
        NAME: 'DittaAlterazioniMalattia',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_ALTERAZIONE_UNATANTUM: {
        TITLE: '',
        LABEL: 'Una tantum',
        PATH: 'alterazioniUnaTantum',
        NAME: 'DittaAlterazioniUnaTantum',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_ALTERAZIONE_MATERNITA: {
        TITLE: '',
        LABEL: 'Maternità',
        PATH: 'AlterazioneMaternita',
        NAME: 'DittaAlterazioneMaternita',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_ALTERAZIONE_ELEMENTI_PAGA: {
        TITLE: '',
        LABEL: 'Elementi Paga',
        PATH: 'AlterazioneElementiPaga',
        NAME: 'DittaAlterazioneElementiPaga',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_ALTERAZIONE_CONTRIBUTIVE: {
        TITLE: '',
        LABEL: 'Contributive',
        PATH: 'AlterazioneContributive',
        NAME: 'DittaAlterazioneContributive',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
    DITTA_DOMANDA_CIG: {
        TITLE: '',
        LABEL: 'Domanda CIG',
        PATH: 'domandaCIG',
        NAME: 'DomandaCIG',
        TAB: { ...tabPropsDefault, TITLE: 'Anagrafica Ditta' },
    },
})

export const routes: Route[] = [
    {
        path: SETTINGS.DITTE_ELENCO.PATH,
        name: SETTINGS.DITTE_ELENCO.NAME,
        component: pagheViews.anagrafiche.ditte.Elenco,
        meta: {
            defaultProps: {
                gestione: (codVoceMenu: string) => mapGestione.get(codVoceMenu),
            },
            title: SETTINGS.DITTE_ELENCO.TITLE,
            permissions: {
                codPermesso: codPermAnagrafica,
                codVoceMenu: tabPropsDefault.CODVOCEMENU,
            },
            tabGroup: tabPropsDefault.GROUP,
        },
        props: true,
    },
    {
        path: SETTINGS.DITTA_DETTAGLIO.PATH,
        name: SETTINGS.DITTA_DETTAGLIO.NAME,
        props: propsFn,
        component: pagheViews.anagrafiche.ditte.dettaglio.Dettaglio,
        meta: {
            title: SETTINGS.DITTA_DETTAGLIO.TITLE,
            permissions: {
                codPermesso: codPermAnagrafica,
                codVoceMenu: null,
            },
            tabGroup: tabPropsDefault.GROUP,
        },
        children: [
            {
                label: SETTINGS.DITTA_ANAGRAFICA.LABEL,
                path: SETTINGS.DITTA_ANAGRAFICA.PATH,
                name: SETTINGS.DITTA_ANAGRAFICA.NAME,
                tab: SETTINGS.DITTA_ANAGRAFICA.TAB,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.Anagrafica,
                entity: [
                    entities.ditte.DittaEntity.Generale,
                    entities.ditte.DittaEntity.UPAttivita,
                    entities.ditte.DittaEntity.UPRagioneSociale,
                ],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT01',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: 0,
                props: propsFn,
            },
            {
                path: SETTINGS.DITTA_DATI_INPS.PATH,
                name: SETTINGS.DITTA_DATI_INPS.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.DatiInps,
                props: propsFn,
                entity: [entities.ditte.DittaEntity.UPEnte, entities.ditte.DittaEntity.UPAltroEnte],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT02',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: 1,
            },
            {
                path: SETTINGS.DITTA_SOST_IMPOSTA.PATH,
                name: SETTINGS.DITTA_SOST_IMPOSTA.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.SostImposta,
                entity: [entities.ditte.DittaEntity.Sostituto],
                props: propsFn,
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT03',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: 2,
            },
            {
                path: SETTINGS.DITTA_DATI_GESTIONE.PATH,
                name: SETTINGS.DITTA_DATI_GESTIONE.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.DatiGestione,
                entity: [entities.ditte.DittaEntity.UPGestione],
                props: propsFn,
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT04',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: 3,
            },
            {
                path: SETTINGS.DITTA_OPZIONI.PATH,
                name: SETTINGS.DITTA_OPZIONI.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.Opzioni,
                entity: [entities.ditte.DittaEntity.UPOpzione],
                props: propsFn,
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT05',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: 4,
            },
            {
                path: SETTINGS.DITTA_CENTRI_COSTO.PATH,
                name: SETTINGS.DITTA_CENTRI_COSTO.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.CentriCosto,
                props: propsFn,
                entity: [entities.ditte.DittaEntity.CentroCosto],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT13',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_ORGANICO.PATH,
                name: SETTINGS.DITTA_ORGANICO.NAME,
                props: propsFn,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.Organico,
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT24',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_OPZIONISTAMPA.PATH,
                name: SETTINGS.DITTA_OPZIONISTAMPA.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.OpzioniStampa,
                props: propsFn,
                entity: [
                    entities.ditte.DittaEntity.UPOpzioneStampa,
                    entities.ditte.DittaEntity.UPReportElabora,
                ],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT06',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_POSIZIONIINAIL.PATH,
                name: SETTINGS.DITTA_POSIZIONIINAIL.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.PosizioniInail,
                props: propsFn,
                entity: [
                    entities.ditte.DittaEntity.InailGenerale,
                    entities.ditte.DittaEntity.InailPosizione,
                ],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT09',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_STORICO.PATH,
                name: SETTINGS.DITTA_STORICO.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.Storico,
                props: propsFn,
                entity: [entities.ditte.DittaEntity.UPStorico],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT15',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_RAPPRESENTANTELEGALE.PATH,
                name: SETTINGS.DITTA_RAPPRESENTANTELEGALE.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.RappresentateLegale,
                props: propsFn,
                entity: [entities.ditte.DittaEntity.Rappresentante],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT10',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_BANCHE.PATH,
                name: SETTINGS.DITTA_BANCHE.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.Banche,
                props: propsFn,
                entity: [entities.ditte.DittaEntity.Banca],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT11',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_GRUPPOALTERAZIONE.PATH,
                name: SETTINGS.DITTA_GRUPPOALTERAZIONE.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.GruppoAlterazione,
                props: propsFn,
                entity: [entities.ditte.DittaEntity.GruppoAlterazione],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT12',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_CASSA_EDILE.PATH,
                name: SETTINGS.DITTA_CASSA_EDILE.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.CassaEdile,
                props: propsFn,
                //entity: [entities.ditte.DittaEntity.],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT18',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_FONDIDMAG.PATH,
                name: SETTINGS.DITTA_FONDIDMAG.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.FondiDmag,
                props: propsFn,
                entity: [entities.ditte.DittaEntity.FondoAgricolo],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT19',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_SCADENZIARIO.PATH,
                name: SETTINGS.DITTA_SCADENZIARIO.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.Scadenziario,
                props: propsFn,
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT20',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            //IMPOSTAZIONE ELABORAZIONE
            {
                path: SETTINGS.DITTA_IMP_CEDOLINO.PATH,
                name: SETTINGS.DITTA_IMP_CEDOLINO.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.ImpCedolino,
                props: propsFn,
                entity: [
                    entities.ditte.DittaEntity.UPVoceFissa,
                    entities.ditte.DittaEntity.UPNotaCedoFissa,
                ],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT26',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_ALTRI_ONERI.PATH,
                name: SETTINGS.DITTA_ALTRI_ONERI.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.AltriOneri,
                props: propsFn,
                entity: [entities.ditte.DittaEntity.UPEnteFisso],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT28',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_FORMULE.PATH,
                name: SETTINGS.DITTA_FORMULE.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.Formule,
                props: propsFn,
                entity: [entities.ditte.DittaEntity.UPFormula],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT27',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_APPALTO.PATH,
                name: SETTINGS.DITTA_APPALTO.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.AppaltiECommesse,
                props: propsFn,
                entity: [entities.ditte.DittaEntity.Appalto],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT17',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_UNITAPRODUTTIVEINPS.PATH,
                name: SETTINGS.DITTA_UNITAPRODUTTIVEINPS.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.UnitaProduttiveINPS,
                props: propsFn,
                entity: [entities.ditte.DittaEntity.InpsUnitaProduttiva],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT16',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_CAUSALIASSENTEISMO.PATH,
                name: SETTINGS.DITTA_CAUSALIASSENTEISMO.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.CausaliAssenteismo,
                props: propsFn,
                entity: [entities.ditte.DittaEntity.Assenteismo],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT14',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_DIARIO.PATH,
                name: SETTINGS.DITTA_DIARIO.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.Diario,
                props: propsFn,
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT21',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_CAMPIPERSONALIZZATI.PATH,
                name: SETTINGS.DITTA_CAMPIPERSONALIZZATI.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.CampiPersonalizzati,
                props: propsFn,
                entity: [entities.ditte.DittaEntity.UPCampoPersonalizzato],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT22',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_CONTRATTOSTRAORDINARIO.PATH,
                name: SETTINGS.DITTA_CONTRATTOSTRAORDINARIO.NAME,
                component:
                    pagheViews.anagrafiche.ditte.dettaglio.childs.alterazioni
                        .ContrattoStraordinario,
                props: propsFn,
                entity: [entities.contratto.ContrattoEntity.Straordinario],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT55',
                    permissions: {
                        codPermesso: codPermAlterazioni,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_ALTERAZIONE_VALORIZZAZIONE_ELEMENTI.PATH,
                name: SETTINGS.DITTA_ALTERAZIONE_VALORIZZAZIONE_ELEMENTI.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.alterazioni.Elementi,
                props: propsFn,
                entity: [entities.altera.AlteraEntity.ValorizzaElePaga],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT51',
                    permissions: {
                        codPermesso: codPermAlterazioni,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_ALTERAZIONE_DIVISORI_ORARIO.PATH,
                name: SETTINGS.DITTA_ALTERAZIONE_DIVISORI_ORARIO.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.alterazioni.DivisoriOrari,
                props: propsFn,
                entity: [entities.contratto.ContrattoEntity.Divisore],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT54',
                    permissions: {
                        codPermesso: codPermAlterazioni,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_ALTERAZIONE_FESTIVITA.PATH,
                name: SETTINGS.DITTA_ALTERAZIONE_FESTIVITA.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.alterazioni.Festivita,
                props: propsFn,
                entity: [entities.contratto.ContrattoEntity.Festivita],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT53',
                    permissions: {
                        codPermesso: codPermAlterazioni,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_ISTITUTI_CONTRATTUALI.PATH,
                name: SETTINGS.DITTA_ISTITUTI_CONTRATTUALI.NAME,
                component:
                    pagheViews.anagrafiche.ditte.dettaglio.childs.alterazioni.IstitutiContrattuali,
                props: propsFn,
                entity: [entities.contratto.ContrattoEntity.IstitutoContr],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT56',
                    permissions: {
                        codPermesso: codPermAlterazioni,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_ALTERAZIONE_MESILITA_AGGIUNTIVE.PATH,
                name: SETTINGS.DITTA_ALTERAZIONE_MESILITA_AGGIUNTIVE.NAME,
                component:
                    pagheViews.anagrafiche.ditte.dettaglio.childs.alterazioni.MensilitaAggiuntive,
                props: propsFn,
                entity: [entities.contratto.ContrattoEntity.MensAgg],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT57',
                    permissions: {
                        codPermesso: codPermAlterazioni,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_ALTERAZIONE_FERIE.PATH,
                name: SETTINGS.DITTA_ALTERAZIONE_FERIE.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.alterazioni.Ferie,
                props: propsFn,
                entity: [entities.contratto.ContrattoEntity.FeExFestRol],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT58',
                    permissions: {
                        codPermesso: codPermAlterazioni,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_ALTERAZIONE_INFORTUNIO.PATH,
                name: SETTINGS.DITTA_ALTERAZIONE_INFORTUNIO.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.alterazioni.Infortunio,
                props: propsFn,
                entity: [entities.contratto.ContrattoEntity.Infortunio],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT60',
                    permissions: {
                        codPermesso: codPermAlterazioni,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_ALTERAZIONE_UNATANTUM.PATH,
                name: SETTINGS.DITTA_ALTERAZIONE_UNATANTUM.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.alterazioni.UnaTantum,
                props: propsFn,
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT63',
                    permissions: {
                        codPermesso: codPermAlterazioni,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_ALTERAZIONE_MALATTIA.PATH,
                name: SETTINGS.DITTA_ALTERAZIONE_MALATTIA.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.alterazioni.Malattia,
                props: propsFn,
                entity: [entities.contratto.ContrattoEntity.Malattia],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT59',
                    permissions: {
                        codPermesso: codPermAlterazioni,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_ALTERAZIONE_MATERNITA.PATH,
                name: SETTINGS.DITTA_ALTERAZIONE_MATERNITA.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.alterazioni.Maternita,
                props: propsFn,
                entity: [entities.contratto.ContrattoEntity.Maternita],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT61',
                    permissions: {
                        codPermesso: codPermAlterazioni,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_ALTERAZIONE_ELEMENTI_PAGA.PATH,
                name: SETTINGS.DITTA_ALTERAZIONE_ELEMENTI_PAGA.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.alterazioni.ElementiPaga,
                props: propsFn,
                entity: [entities.altera.AlteraEntity.ElePaga],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT50',
                    permissions: {
                        codPermesso: codPermAlterazioni,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_ALTERAZIONE_CONTRIBUTIVE.PATH,
                name: SETTINGS.DITTA_ALTERAZIONE_CONTRIBUTIVE.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.alterazioni.Contributive,
                props: propsFn,
                entity: [entities.contratto.ContrattoEntity.Ente],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT62',
                    permissions: {
                        codPermesso: codPermAlterazioni,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_PROGRESSIONE_RETRIBUTIVA.PATH,
                name: SETTINGS.DITTA_PROGRESSIONE_RETRIBUTIVA.NAME,
                component:
                    pagheViews.anagrafiche.ditte.dettaglio.childs.alterazioni.ProgRetributiva,
                props: propsFn,
                entity: [entities.contratto.ContrattoEntity.ProgrRetrib],
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT52',
                    permissions: {
                        codPermesso: codPermAlterazioni,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
            {
                path: SETTINGS.DITTA_DOMANDA_CIG.PATH,
                name: SETTINGS.DITTA_DOMANDA_CIG.NAME,
                component: pagheViews.anagrafiche.ditte.dettaglio.childs.DomandaCIG,
                props: propsFn,
                meta: {
                    group: SETTINGS.DITTA_DETTAGLIO.NAME,
                    idPaginaMsg: 'DT25',
                    permissions: {
                        codPermesso: codPermAnagrafica,
                        codVoceMenu: null,
                    },
                    tabGroup: tabPropsDefault.GROUP,
                },
                orderWizard: null,
            },
        ],
    },
]

export default {
    SETTINGS,
    routes,
}
