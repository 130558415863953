import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL = '/VDizFineAnno'

class API {
    @call({ url: `${BASE_URL}/Get` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<VDizFineAnno>> | null> {
        return null
    }
    @call({ url: `${BASE_URL}/GetVDizFineAnno` })
    static async getVDizFineAnno(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<VDizFineAnno>> | null> {
        return null
    }
}

export default {
    get: API.get,
    getVDizFineAnno: API.getVDizFineAnno,
}
