import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL = '/Formula'

class API {
    @call({ url: `${BASE_URL}/GetFormula` })
    static async get<T extends Payload>(
        payload: T,
    ): Promise<AxiosResponse<BasicResponseData<GetFormula>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveFormula` })
    static async save(
        payload: SaveFormule,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveFormule>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
}
