import { call } from '@/scripts/core/axios'
import { AxiosResponse } from 'axios'

const BASE_URL = '/Assemblaggio'
class API {
    @call({ url: `${BASE_URL}/CalcolaAllAssemblaggi` })
    static async calcolaAllAssemblaggi(
        payload: AssemblaggioPayload,
    ): Promise<AxiosResponse<BasicResponsePayload<CalcolaAllAssemblaggi>> | null> {
        return null
    }
}

export default {
    calcolaAllAssemblaggi: API.calcolaAllAssemblaggi,
}
