export const ContrattoEntity = {
    Apprendista: 'Contratto_Apprendista',
    Divisore: 'Contratto_Divisore',
    ElePaga: 'Contratto_ElePaga',
    Ente: 'Contratto_Ente',
    Fattispecie: 'Contratto_Fattispecie',
    FeExFestRol: 'Contratto_FeExFestRol',
    Festivita: 'Contratto_Festivita',
    Generale: 'Contratto_Generale',
    Infortunio: 'Contratto_Infortunio',
    IstitutoContr: 'Contratto_IstitutoContr',
    Livello: 'Contratto_Livello',
    Malattia: 'Contratto_Malattia',
    Maternita: 'Contratto_Maternita',
    MensAgg: 'Contratto_MensAgg',
    ProgrRetrib: 'Contratto_ProgrRetrib',
    Qualifica: 'Contratto_Qualifica',
    Scatto: 'Contratto_Scatto',
    Straordinario: 'Contratto_Straordinario',
    Suddivisione: 'Contratto_Suddivisione',
    UnaTantum: 'Contratto_UnaTantum',
} as const

export type ContrattoEntityKey = keyof typeof ContrattoEntity
export type ContrattoEntityDbValue = (typeof ContrattoEntity)[ContrattoEntityKey]

export const TotaliSviluppoLivello = {
    LivelloBase: 'Livello base',
    SuddivisioneContrattuale: 'Suddivisione Contrattuale',
    SuddivisioneTerritoriale: 'Suddivisione Territoriale',
    Suddivisioni: 'Suddivisioni',
    Totale: 'TOTALE',
} as const

export type TotaliSviluppoLivelloKey = keyof typeof TotaliSviluppoLivello
export type TotaliSviluppoLivelloValue = (typeof TotaliSviluppoLivello)[TotaliSviluppoLivelloKey]

export default { ContrattoEntity, TotaliSviluppoLivello }
