const Generale = () => import('./Generale.vue')
const SuddContrTerr = () => import('./SuddContrTerr.vue')
const ElementiPaga = () => import('./ElementiPaga.vue')
const DivisoriOrario = () => import('./DivisoriOrario.vue')
const Straordinario = () => import('./Straordinario.vue')
const Festivita = () => import('./Festivita.vue')
const FeExFestRol = () => import('./FeExFestRol.vue')
const IstitutiContrattuali = () => import('./IstitutiContrattuali.vue')
const MensilitaAggiuntive = () => import('./MensilitaAggiuntive.vue')
const Malattia = () => import('./Malattia.vue')
const Infortunio = () => import('./Infortunio.vue')
const Maternita = () => import('./Maternita.vue')
const UnaTantum = () => import('./UnaTantum.vue')
const Livello = () => import('./Livello.vue')
const ProgressioneRetributiva = () => import('./ProgressioneRetributiva.vue')

export default {
    DivisoriOrario,
    ElementiPaga,
    FeExFestRol,
    Festivita,
    Generale,
    Infortunio,
    IstitutiContrattuali,
    Livello,
    Malattia,
    Maternita,
    MensilitaAggiuntive,
    ProgressioneRetributiva,
    Straordinario,
    SuddContrTerr,
    UnaTantum,
}
