import { it } from 'vuetify/locale'

export default {
    ...it,
    open: 'Apri',
    validations: {
        alpha: "Il campo accetta solo lettere dell'alfabeto",
        alphaNum: 'Il campo accetta solo caratteri alfanumerici',
        required: 'Il campo non può essere vuoto.',
        integer: 'Il campo accetta solo numeri interi.',
        decimal: 'Il campo accetta solo numeri con decimali.',
        numeric: 'Il campo accetta solo numeri.',
        requiredIf: 'Il campo è richiesto',
        email: 'E-mail non valida',
        maxValue: 'Il valore massimo ammesso è {1}.',
        minValue: 'Il valore minimo ammesso è {1}.',
        maxLength: 'Numero caratteri errato (massimo {1} carattere/i)',
        minLength: 'Numero caratteri errato (minimo {1} carattere/i)',
    },
}
