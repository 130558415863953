import * as childs from './childs'
const dettaglio = () => import('./Dettaglio.vue')
const ParametriModal = () => import('./ParametriModal.vue')
const RightHeader = () => import('./RightHeader.vue')

export type BaseProps = {
    year: number
    month: number
    day?: number
    attualeCheck: number
    allValidate: number
    codTable?: string
}

export const baseProps: BaseProps = {
    year: 0,
    month: 0,
    day: 1,
    attualeCheck: 0,
    allValidate: 0,
    codTable: '',
}
export default { childs, dettaglio, ParametriModal, RightHeader }
